<template>
  <div>
    <!-- Page content -->
    <b-container class="app-custom-width">
        <b-row class="justify-content-center mb-2">
          <b-col lg="6" md="7">

          <div class="text-center custom-circle" >
          </div>

          </b-col>
        </b-row>
      <b-row class="justify-content-center">
         
        <b-col lg="5" md="7">
          <b-card no-body class="bg-white border-0 mb-0">
                  
            <b-card-body class="px-lg-4 ">
              <div class=" text-muted mb-2">
                  <img src="@/assets/images/logo/Logo_nieuw_RGB.svg" class="app-local-image-logo" right height="130px" alt="Happy One" />               
              </div>

              <div class="mb-2">
                <h4 class="pb-1">{{trans('auth-reset-password-requested',279)}}</h4>
                 
                <div class="mb-3 custom-font-size"> {{trans('auth-email-reset-password-sent', 279, { email :email})}}</div>
                
                <div class="custom-font-size">{{trans('auth-reset-password-email-sent-info',279)}} </div>
              </div>
        
              <div class="text-center">
                   <app-button type="primary" class="my-4" :block="true" size="md" @click="$router.push({ name: 'Login'})">{{trans('back-to-login',285)}}</app-button>
              </div>
            </b-card-body>
          </b-card>
          
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>


  export default {
    

    props:['email'],

    data() {
      return {
      };
    },
    methods: {
      }
    };
</script>

<style scoped>

.vertical-center {
  min-height: calc(100% -64px); 
  min-height: calc(100vh - 64px); 

  display: flex;
  align-items: center;
}
.app-custom-width{
  max-width: 1200px !important;
}
.custom-circle{
  height:24px; 
  width:24px;
}

.app-local-image-logo{
  margin-left: -20px;
}

.custom-font-size{
  font-size: 14px;
}
</style>
