<template>
  <div class="vertical-center">
    <!-- Page content -->
    <b-container class="verical-center app-custom-width">
      
        <b-row class="justify-content-center mb-2">
          <b-col lg="5" md="7">

          <div class="text-center custom-circle">
            <router-link :to="'login'" class="text-center align-items-center">
                <b-icon icon="arrow-left"  class="custom-arrow"></b-icon>
            </router-link>
          </div>

          </b-col>
        </b-row>
      <b-row class="justify-content-center">
         
        <b-col lg="5" md="7">
          <b-card no-body class="bg-white border-0 mb-0  ">
                  
            <b-card-body class="px-lg-4">
              <div class=" text-muted mb-2">
                  <img src="@/assets/images/logo/Logo_nieuw_RGB.svg" class="app-local-image-logo" right height="130px" alt="Happy One" />               
              </div>

              <div class="mb-3">
                <h4 class="pb-1">{{trans('forgot_password',277)}}</h4>
                <span class="custom-font-size">{{trans('forgot_password_text',277)}}</span>
              </div>
         
               <ValidationObserver ref="form">
              <div >
                <app-input v-model="cEmail" validatorRules="required|email" @enter="sendMail" class="mb-4" :label="trans('email',283)"  :validatorName="trans('email',283)"/>
              </div>
               </ValidationObserver>
              <div class="text-center">
                   <app-button type="primary" class="my-2" :loading="loading" :block="true" size="md" @click="sendMail">{{trans('auth-new-password-request',284)}}</app-button>
              </div>
            </b-card-body>
          </b-card>
          
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import axios from "@axios";
import {ValidationObserver} from "vee-validate";
import AppAlerts from '@core/scripts/AppAlerts';

  export default {
    
    components: {
        ValidationObserver
    },
    props:['return', 'email'],

    watch: {
        "cEmail":{
            handler: function(val) {
                if(val != undefined){
                  this.error= 'false';
                  this.$refs.form.validate();
                }
                
            }
        },
    },

    computed: {
       

        cEmail: {
            get() { return this.email },
            set(value) {this.$emit('update:email', value) }, 
        },
    },



    data() {
      return {
        rememberMe: false,
        loading: false,
        alertClass: new AppAlerts()
      };
    },
    methods: {
      sendMail() {
       
       
        this.$refs.form.validate().then((result) => {
          if (!result) {
              return false;
          }else{
           this.loading = true;
          axios.post("authorization/forgotPassword", {
                    email: this.cEmail
                })
              .then(() => {
                  this.$emit('update:return', 2); 
              })
              .catch((error) => {
                   console.error( {error});
              }).finally(() => {
                        this.loading = false;
              }); 
        }
        });
        }
      }
    };
</script>

<style scoped>
.custom-arrow{
  height: 15px; 
  width:15px; 
  margin-bottom: 2px
}

.vertical-center {
  min-height: calc(100% -64px); 
  min-height: calc(100vh - 64px); 

  display: flex;
  align-items: center;
}

.app-custom-width{
  max-width: 1200px !important;
}

.custom-circle{
  border-radius: 50%; 
  background:white; 
  height:24px; 
  width:24px;
}

.app-local-image-logo{
  margin-left: -20px;
}

.custom-font-size{
  font-size: 14px;
}
</style>
