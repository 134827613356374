<template>
  <div class="vertical-center justify-content-center">

      <forgot-password-step1-component :return.sync="current_step" :email.sync="email" v-if="current_step == 1"/>
      <forgot-password-step2-component :return.sync="current_step" :email="email" v-else/>
  </div>
</template>
<script>


import ForgotPasswordStep1Component from './ForgotPasswordStep1Component'
import ForgotPasswordStep2Component from './ForgotPasswordStep2Component'

  export default {
    
    components: {
        ForgotPasswordStep1Component,
        ForgotPasswordStep2Component

    },

    data() {
      return {
        current_step: 1,
        email: '',
      };
    },
    };
</script>

<style scoped>


.custom-arrow{
  height: 15px; 
  width:15px; 
  margin-bottom: 2px
}

.vertical-center {
  min-height: calc(100% -64px); 
  min-height: calc(100vh - 64px); 


  display: flex;
  align-items: center;
}
</style>
